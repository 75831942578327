import { render, staticRenderFns } from "./ImportProducts.vue?vue&type=template&id=5167a600&scoped=true&"
import script from "./ImportProducts.vue?vue&type=script&lang=js&"
export * from "./ImportProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5167a600",
  null
  
)

export default component.exports