import auth from "@/router/middlewares/authMiddleware";
import ShowBankMovements from "@/components/pages/Accounting/BankMovements/ShowBankMovements";
import ShowInvoices from "@/components/pages/Invoices/Accounting/ShowInvoices";
import EditInvoice from "@/components/pages/Invoices/Accounting/EditInvoice";
import CreateInvoice from "@/components/pages/Invoices/Accounting/CreateInvoice";
import EditBankMovement from "@/components/pages/Accounting/BankMovements/Edit/EditBankMovement";
import ShowBankAccounts from "@/components/pages/Accounting/BankMovements/Accounts/ShowBankAccounts";
import ShowPayments from "@/components/pages/Payments/ShowPayments";
import ShowPaymentsLog from "@/components/pages/Payments/ShowPaymentsLog";
import ExternalInvoicePDFsList from "@/components/pages/Accounting/ExternalInvoicePDFs/ExternalInvoicePDFsList.vue";

export default [
  {
    path: '/accounting/bank-movements/:bankAccount?',
    name: 'accounting.bankMovements.list',
    component: ShowBankMovements,
    meta: {
      middleware: [auth],
      title: 'Liste des movements bancaires',
      permissions: ['BANK_MOVEMENT_READ']
    }
  },
  {
    path: '/accounting/bank-accounts',
    name: 'accounting.bankAccounts.list',
    component: ShowBankAccounts,
    meta: {
      middleware: [auth],
      title: 'Liste des comptes',
      permissions: ['BANK_MOVEMENT_FULL_ACCESS']
    }
  },
  {
    path: '/accounting/bank-movements/:id/edit',
    name: 'accounting.bankMovements.edit',
    component: EditBankMovement,
    meta: {
      middleware: [auth],
      title: 'Modifier mouvement bancaire',
      breadcrumbs: ['accounting.bankMovements.list'],
      permissions: ['BANK_MOVEMENT_LINK']
    }
  },
  {
    path: '/accounting/invoices',
    name: 'accounting.invoices.list',
    component: ShowInvoices,
    meta: {
      middleware: [auth],
      title: 'Compta - Liste des factures',
      permissions: ['INVOICE_READ', 'INTERNAL_INVOICE_READ']
    }
  },
  {
    path: '/accounting/invoices/create',
    name: 'accounting.invoices.create',
    component: CreateInvoice,
    meta: {
      middleware: [auth],
      title: 'Créer facture',
      breadcrumbs: ['accounting.invoices.list'],
      permissions: ['INVOICE_WRITE', 'INTERNAL_INVOICE_WRITE']
    }
  },
  {
    path: '/accounting/invoices/:id/edit',
    name: 'accounting.invoices.edit',
    component: EditInvoice,
    meta: {
      middleware: [auth],
      title: 'Modifier facture',
      breadcrumbs: ['accounting.invoices.list'],
      permissions: ['INVOICE_WRITE', 'INTERNAL_INVOICE_WRITE'],
      readOnly: {
        supported: true,
        title: 'Voir une facture',
        permissions: ['INVOICE_READ']
      },
    }
  },
  {
    path: '/accounting/paymentTransactions',
    name: 'accounting.payments.list',
    component: ShowPayments,
    meta: {
      middleware: [auth],
      title: 'Compta - Payments',
      permissions: ['ACCESS_TRANSACTION_LIST_VIEW']
    }
  },
  {
    path: '/accounting/paymentTransactionsLog',
    name: 'accounting.paymentsLog.list',
    component: ShowPaymentsLog,
    meta: {
      middleware: [auth],
      title: 'Compta - Payments Log',
      permissions: ['ACCESS_TRANSACTION_LIST_VIEW']
    }
  },
  {
    path: '/accounting/external-invoice-pdfs',
    name: 'accounting.external-invoice-pdfs.list',
    component: ExternalInvoicePDFsList,
    meta: {
      middleware: [auth],
      title: 'PDFs Factures externe',
      permissions: ['INVOICE_PDF_READ']
    }
  },
]