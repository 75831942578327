<template>
  <div
    class="md:border-r border-gray-200 bg-gray-800 z-30 overflow-y-auto"
  >
    <div class="py-4 px-3 ">
      <!-- User Information -->
      <sidebar-user-display/>

      <!-- Sidebar Links -->
      <sidebar-links
              v-if="!$store.getters['authUser/isSupplier']"
      />

      <sidebar-links-supplier
        v-if="$store.getters['authUser/isSupplier']"
        class="mt-2"
      />
    </div>
  </div>
</template>

<script>
import SidebarUserDisplay from "./sidebar/SidebarUserDisplay";
import {isScreenSizeBelowBreakpoint} from "@/helpers/screenWidthHelper";
import SidebarLinks from "@/components/layouts/dashboard/partials/sidebar/linklists/SidebarLinks";
import SidebarLinksSupplier from "@/components/layouts/dashboard/partials/sidebar/linklists/SidebarLinksSupplier";

export default {
  name: "DashboardSidebar",
  components: {SidebarLinksSupplier, SidebarLinks, SidebarUserDisplay},
  watch: {
    isSidebarShown(shown) {
      if (shown) {
        this.blockBackgroundScroll();
      } else {
        this.unblockBackgroundScroll();
      }
    }
  },
  methods: {
    blockBackgroundScroll() {
      if (isScreenSizeBelowBreakpoint("md")) {
        document.getElementsByTagName("html")[0].classList.add('overflow-y-hidden');
      }
    },
    unblockBackgroundScroll() {
      if (isScreenSizeBelowBreakpoint("md")) {
        document.getElementsByTagName("html")[0].classList.remove('overflow-y-hidden');
      }
    }
  },

}
</script>

