<template>
    <list-table
            :key="getTableKey"
            :columns="columns"
            :filters="filters"
            :pagination="true"
            :repository-func="repositoryFunc"
            :title="title"
            identifier="subscriptionsTable"
            show-filters-immediately
            :select-values="selectValues"
            enable-totals
            selectable
    >

        <template v-slot:destination-template="{ row }">
            <div class="font-bold text-sm mb-1">
                {{ row.fullName }}
            </div>
            <div class="text-xs">
                <p>{{ row.street }}, {{ row.postalCode }} {{ row.city }},
                    {{ row.country | toCountryNameString }}</p>
            </div>
        </template>
        <template v-slot:product-ids-template="{ row }">
            <div class="flex flex-col gap-2">
                <span v-for="(item) in row.items" :key="item.productId"  class="flex text-sm">
                   <span class="flex text-sm" >{{item.quantity}} X </span>   <product-quick-action-modal :id="item.productId" class="ml-1"/>
                </span>
            </div>
        </template>


        <template v-slot:member-quick-action-template="{ row }">
            <member-quick-action-modal :id="row.memberId"/>
        </template>

        <template v-slot:subscription-quick-action-template="{ row }">
            <subscription-quick-action-modal :subscription-id="row.id"/>
        </template>
    </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";
import {
    FILTER_BOOLEAN, FILTER_DATETIME_RANGE,
    FILTER_TEXT
} from "../../listTable/includes/controls/filters/availableFilters";
import SubscriptionRepository from "@/repositories/SubscriptionRepository";
import {centsToEuroString} from "@/helpers/mathHelper";
import ProductQuickActionModal from "@/components/pages/Products/Subcomponents/ProductQuickActionModal.vue";
import SubscriptionQuickActionModal
    from "@/components/pages/Subscription/Subcomponents/SubscriptionQuickActionModal.vue";

export default {
    name: "ShowSubscriptions",
    components: {
        SubscriptionQuickActionModal,
        ProductQuickActionModal,
        MemberQuickActionModal,
        ListTable
    },
    computed: {
        // used as a workaround to render the table again when switching from a shortcut (today's orders, tomorrows order, ...) to the regular page,
        getTableKey() {
            return this.$route.name + '_' + Math.random();
        }
    },
    data() {
        return {

            selectValues: subscription => {
                return {
                    id: subscription.id
                };
            },
            title: 'Abonnements',
            repositoryFunc: SubscriptionRepository.getAllPaginated,
            filters: [
                {
                    caption: "ID(s)",
                    filterKey: "subscriptionIds",
                    forceIsVisible: true,
                    small: true,
                },
                {
                    caption: "ID(s) ou Nom Membre",
                    filterKey: "memberIdsOrName",
                    forceIsVisible: true,
                    small: true,
                },
                {
                    caption: "Zone",
                    filterKey: "zone",
                    type: FILTER_TEXT,
                    forceIsVisible: true,
                    small: true
                },
                {
                    caption: "Code postal",
                    filterKey: "postalCode",
                    type: FILTER_TEXT,
                    small: true,
                    forceIsVisible: true
                },
                {
                    caption: 'Actif',
                    filterKey: 'active',
                    type: FILTER_BOOLEAN,
                    small: true,
                    forceIsVisible: true,
                    defaultValue: 1
                },
                {
                    caption: "Crée entre",
                    filterKey: "createdAt",
                    type: FILTER_DATETIME_RANGE,
                    forceIsVisible: true
                },
            ],
            columns: [
                {
                    caption: "Actions",
                    buttons: [
                        {
                            type: 'delete',
                            hint: 'Anuller abonnement',
                            condition: (row) => row.isActive,
                            confirmation: {
                                title: 'Annuler l\'abonnement ?',
                                text: 'Étes vous sure que vous voulez annuler cet abonnement ?'
                            },
                            deleteAction: (row) => SubscriptionRepository.cancel({
                                memberId: row.memberId,
                                subscriptionId: row.id
                            }),
                            successText: 'Abonnement annulé.',
                            deletePermissions: ['WRITE_SUBSCRIPTION']
                        },
                        {
                            type: 'delete',
                            hint: 'Reactiver abonnement',
                            icon: 'check',
                            condition: (row) => !row.isActive,
                            confirmation: {
                                title: 'Reactiver l\'abonnement?',
                                text: 'Étes vous sure que vous voulez reactiver cet abonnement ?'
                            },
                            deleteAction: (row) => SubscriptionRepository.reactivate({
                                memberId: row.memberId,
                                subscriptionId: row.id
                            }),
                            successText: 'Abonnement reactivé.',
                            deletePermissions: ['WRITE_SUBSCRIPTION']
                        },
                    ]
                },
                {
                    caption: '#',
                    cellTemplate: 'subscription-quick-action-template',
                    sort: {
                        dataField: 'id',
                        default: 'desc'
                    }
                },
                {
                    caption: 'Mem',
                    cellTemplate: 'member-quick-action-template'
                },
                {
                    caption: 'Destination',
                    cellTemplate: 'destination-template',
                },
                {
                    caption: 'Products',
                    cellTemplate: 'product-ids-template',
                },
                {
                    caption: 'Jours de livraison',
                    calculateCellValue: r => {
                        const availableWeekDays = this.$luxcaddyConfig('subscriptions.availableWeekDays');
                        // Filter and map to get the labels of the matching week days
                       return availableWeekDays
                            .filter(day => r.intervalWeekDays.includes(day.value))
                            .map(day => day.label)
                            .join(', ');
                    },
                },
                {
                    caption: 'Toutes les (semaines)',
                    dataField: 'orderIntervalWeek',
                    width: 80
                },
                {
                    caption: 'Total TTC',
                    dataType: 'euro',
                    dataField: 'total.amount',
                    totals: {
                        sum: "total.amount",
                        formatValue: (val) => centsToEuroString(val)
                    },
                    visible: true
                },
                {
                    caption: 'Active',
                    dataType: 'boolean',
                    calculateCellValue: (r) => r.isActive,

                },
                {
                    caption: 'Date abbon,',
                    calculateCellValue: (r) => this.$date(r.createdAt).format('DD.MM.YY HH:mm'),
                    sort: {
                        dataField: 'createdAt',
                    },
                    visible: true
                },
                {
                    caption: 'Première livrais.',
                    calculateCellValue: (r) => this.$date(r.firstDeliveryDate).format('DD.MM.YY HH:mm'),
                    sort: {
                        dataField: 'createdAt',
                    },
                    visible: true
                },
            ]
        }
    },
}
</script>

