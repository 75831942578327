<template>
    <card size="small" title="Importation products fournisseur">
        <form-wrapper ref="form"
                      :show-changed="true"
                      submit-button-label="Télécharger"
                      @submit="uploadFile"
        >
            <div class="grid grid-cols-2 gap-4">
                <supplier-select-input
                        v-model="supplierId"
                        label="Fournisseur"
                        validation-rules="required"
                />

                <file-upload-button-element
                        v-model="file"
                        label="Catalogue (.csv)"
                        validation-rules="required"
                />
            </div>

        </form-wrapper>
    </card>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import SupplierSelectInput from "@/components/elements/forms/elements/SupplierSelectInput";
import FileUploadButtonElement from "@/components/elements/forms/elements/upload/FileUploadButtonElement";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import ProductRepository from "@/repositories/ProductRepository";

export default {
    name: "ImportProducts",
    components: {FormWrapper, FileUploadButtonElement, SupplierSelectInput, Card},
    data: () => ({
        supplierId: null,
        file: null
    }),
    methods: {
        uploadFile(callback) {
            ProductRepository.upload(this.supplierId, this.file)
                .then((res) => {
                    // Extract notifications
                    const notices = res.data.notifications.notices || [];
                    const warnings = res.data.notifications.warnings || [];

                    // Extract total imported products notice
                    const totalNotice = notices.find(n => n.key === "importedProducts")?.msg || "No products imported";

                    // Format warnings
                    let warningMessages = "";
                    if (warnings.length > 0) {
                        warningMessages = warnings.map(w => `- ${w.msg}`).join("<br>");
                    } else {
                        warningMessages = "No warnings.";
                    }

                    // Construct message
                    let message = `
                    <span class='font-bold underline'>Total:</span> ${totalNotice}<br><br>
                    <span class='font-bold underline'>Errors:</span><br>
                    ${warningMessages}
                `;

                    // Show success toast
                    this.$sendSuccessToast("Products téléchargé!");

                    // Emit formatted message
                    this.$emit('change', message);
                }).finally(() => callback());
        }
    },
}
</script>

<style scoped>

</style>