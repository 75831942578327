<template>
  <div
    :class="{'bg-blue-200 rounded-md px-2 pb-2': isFilterSet}"
    class="w-full flex items-end gap-x-1 overflow-visible"
  >
    <div class="flex-grow flex-shrink-0 w-11/12">
      <text-input-element
        v-if="type === FILTER_TEXT"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
        @enter="$emit('enter', $event)"
        @escape="$emit('escape', $event)"
      ></text-input-element>

      <number-input-element
        v-if="type === FILTER_NUMERIC"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
        @enter="$emit('enter', $event)"
        @escape="$emit('escape', $event)"
      ></number-input-element>

      <currency-input-element
        v-else-if="type === FILTER_CURRENCY"
        :id="filterKey"
        v-model.number="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
        allow-negative
        @enter="$emit('enter', $event)"
        @escape="$emit('escape', $event)"

      ></currency-input-element>

      <email-input-element
        v-else-if="type === FILTER_EMAIL"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
        @enter="$emit('enter', $event)"
        @escape="$emit('escape', $event)"
      ></email-input-element>

      <select-element
        v-else-if="type === FILTER_SELECT_SINGLE || type === FILTER_SELECT_MULTI"
        :id="filterKey"
        v-model="value"
        :enable-search="false"
        :label="caption"
        :multi="type === FILTER_SELECT_MULTI"
        :occupy-validation-space="false"
        :options="filterOptions"
        active-in-read-only
        class="w-full"
      />

      <date-picker-element
        v-else-if="type === FILTER_DATETIME"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        with-time
        @enter="$emit('enter', $event)"
        @escape="$emit('escape', $event)"
      ></date-picker-element>

      <date-range-picker-element
        v-else-if="type === FILTER_DATETIME_RANGE && value !== null"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        end-date-end-of-day
        prefill-end-date
        small-text
        with-time
        @enter="$emit('enter', $event)"
        @escape="$emit('escape', $event)"
      />

      <list-table-tag-type-selection
        v-else-if="type === FILTER_TAG_TYPE"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
      >
      </list-table-tag-type-selection>

      <category-selection-input
        v-else-if="type === FILTER_PRODUCT_CATEGORY"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
        multi
        type="product"
      >
      </category-selection-input>

      <member-select-input
        v-else-if="type === FILTER_MEMBER_EMPLOYEES"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        :only-member-types="['employee']"
        active-in-read-only
      />
      <member-select-input
        v-else-if="type === FILTER_MEMBER_EMPLOYEES_MULTI"
        :id="filterKey"
        v-model="value"
        :label="caption"
        multi
        :occupy-validation-space="false"
        :only-member-types="['employee']"
        active-in-read-only
      />
      <member-select-input
        v-else-if="type === FILTER_MEMBER_CUSTOMERS"
        :id="filterKey"
        v-model="value"
        :label="caption"

        :occupy-validation-space="false"
        :only-member-types="['customer']"
        active-in-read-only
      />
      <member-select-input
        v-else-if="type === FILTER_MEMBER"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
      />

      <language-select-input
        v-else-if="type === FILTER_LANGUAGE_SELECT"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
      >
      </language-select-input>

      <country-select-input
        v-else-if="type === FILTER_COUNTRY_SELECT"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
      >
      </country-select-input>

      <supplier-select-input
        v-else-if="type === FILTER_PRODUCT_SUPPLIER || type === FILTER_SUPPLIER"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        :only-product-supplier="type === FILTER_PRODUCT_SUPPLIER"
        active-in-read-only
      >
      </supplier-select-input>

      <supplier-select-input
        v-else-if="type === FILTER_PRODUCT_SUPPLIER_MULTI"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
        only-product-supplier
        type="multi"

      >
      </supplier-select-input>


      <select-element
        v-else-if="type === FILTER_BOOLEAN"
        :id="filterKey"
        v-model="value"
        :enable-search="false"
        :label="caption"
        :occupy-validation-space="false"
        :options="[
                    {value: null, label: 'Tous'},
                    {value: 1, label: 'Oui'},
                    {value: 0, label: 'Non'},
                  ]"
        active-in-read-only
      >
      </select-element>
      <checkbox-element
        v-else-if="type === FILTER_SINGLE_BOOLEAN"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
        emit-as-number
      ></checkbox-element>

      <product-select-input
        v-else-if="type === FILTER_PRODUCT_SINGLE || type === FILTER_PRODUCT_MULTI"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        :type="type === FILTER_PRODUCT_SINGLE ? 'single' : 'multi'"
        active-in-read-only
      ></product-select-input>

      <device-select-input
        v-else-if="type === FILTER_DEVICE"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        active-in-read-only
      ></device-select-input>

      <product-tag-selection-input
        v-else-if="type === FILTER_PRODUCT_TAG_SINGLE || type === FILTER_PRODUCT_TAG_MULTI"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        :select-multiple="type === FILTER_PRODUCT_TAG_MULTI"
        active-in-read-only
      />

      <select-element
        v-else-if="type === FILTER_LOGGABLE_TYPE"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        :options="getFilterOptions"
        active-in-read-only
      />

      <select-element
        v-else-if="type === FILTER_LOG_ACTIONS"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        :options="getFilterOptions"
        active-in-read-only
      />

      <select-element
        v-else-if="type === FILTER_ORDER_ITEM_TYPE"
        v-model="value"
        :enable-search="false"
        :label="caption"
        :occupy-validation-space="false"
        :options="getOrderItemTypes"
        active-in-read-only
      />

      <department-select-input
        v-else-if="type === FILTER_DEPARTMENT_SINGLE || type === FILTER_DEPARTMENT_MULTI"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        :type="type === FILTER_DEPARTMENT_SINGLE ? 'single' : 'multi'"
        active-in-read-only
      >
      </department-select-input>

      <booking-account-select-input
        v-else-if="type === FILTER_BOOKING_ACCOUNT"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        multi
      />

      <role-select-input
        v-else-if="type === FILTER_ROLES"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
        type="multi"
      />

      <permission-select-input
        v-else-if="type === FILTER_PERMISSIONS"
        :id="filterKey"
        v-model="value"
        :label="caption"
        :occupy-validation-space="false"
      >
      </permission-select-input>
    </div>


    <!--
        X Icon will be shown in these cases:
        - Filter is forced to be always visible -> Only when value set
        - Filter is initially hidden -> Always show

        AND only when showResetButton is true. It is false for the first input in a filter with sub inputs.
    -->
    <div
      v-if="(!getFilterFromStore.forceIsVisible || isFilterSet) && showResetButton"
      class="flex-none"
    >
      <fa-icon
        class="text-gray-400 hover:text-gray-600 cursor-pointer text-sm"
        fixed-width
        icon="times"
        @click="resetValue"
      />
    </div>
  </div>
</template>
<script>
import TextInputElement from "../../../../elements/forms/elements/base/TextInputElement";
import CurrencyInputElement from "../../../../elements/forms/elements/CurrencyInputElement";
import EmailInputElement from "../../../../elements/forms/elements/EmailInputElement";
import DatePickerElement from "../../../../elements/forms/elements/base/DatePickerElement";
import ListTableTagTypeSelection from "./elements/ListTableTagTypeSelection";
import CategorySelectionInput from "../../../../elements/forms/elements/CategorySelectionInput";
import LanguageSelectInput from "../../../../elements/forms/elements/LanguageSelectInput";
import SupplierSelectInput from "../../../../elements/forms/elements/SupplierSelectInput";
import CheckboxElement from "../../../../elements/checkboxes/CheckboxElement";

import {
    FILTER_BOOKING_ACCOUNT,
    FILTER_BOOLEAN,
    FILTER_CURRENCY,
    FILTER_DATETIME,
    FILTER_DATETIME_RANGE,
    FILTER_DEPARTMENT_MULTI,
    FILTER_DEPARTMENT_SINGLE,
    FILTER_DEVICE,
    FILTER_EMAIL,
    FILTER_LANGUAGE_SELECT,
    FILTER_LOGGABLE_TYPE,
    FILTER_MEMBER,
    FILTER_MEMBER_CUSTOMERS,
    FILTER_MEMBER_EMPLOYEES,
    FILTER_MEMBER_EMPLOYEES_MULTI,
    FILTER_LOG_ACTIONS,
    FILTER_NUMERIC,
    FILTER_ORDER_ITEM_TYPE,
    FILTER_PERMISSIONS,
    FILTER_PRODUCT_CATEGORY,
    FILTER_PRODUCT_MULTI,
    FILTER_PRODUCT_SINGLE,
    FILTER_PRODUCT_SUPPLIER,
    FILTER_PRODUCT_SUPPLIER_MULTI,
    FILTER_PRODUCT_TAG_MULTI,
    FILTER_PRODUCT_TAG_SINGLE,
    FILTER_ROLES,
    FILTER_SELECT_MULTI,
    FILTER_SELECT_SINGLE,
    FILTER_SINGLE_BOOLEAN,
    FILTER_SUPPLIER,
    FILTER_TAG_TYPE,
    FILTER_TEXT, FILTER_COUNTRY_SELECT
} from "./availableFilters";
import ProductTagSelectionInput from "../../../../elements/forms/elements/ProductTagSelectionInput";
import ProductSelectInput from "../../../../elements/forms/elements/ProductSelectInput";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput";
import LogsRepository from "@/repositories/LogsRepository";
import DepartmentSelectInput from "@/components/elements/forms/elements/DepartmentSelectInput";
import NumberInputElement from "../../../../elements/forms/elements/base/NumberInputElement";
import BookingAccountSelectInput from "@/components/elements/forms/elements/BookingAccountSelectInput.vue";
import DateRangePickerElement from "@/components/elements/forms/elements/base/dateRange/DateRangePickerElement.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import {isFilterSet} from "@/helpers/ListTable/ListTableFilterHelper";
import DeviceSelectInput from "@/components/elements/forms/elements/DeviceSelectInput.vue";
import RoleSelectInput from "@/components/elements/forms/elements/RoleSelectInput.vue";
import PermissionSelectInput from "@/components/elements/forms/elements/PermissionSelectInput.vue";
import CountrySelectInput from "@/components/elements/forms/elements/CountrySelectInput.vue";

export default {
  name: "ListTableFilterInput",
  components: {
    CountrySelectInput,
    PermissionSelectInput,
    RoleSelectInput,
    DeviceSelectInput,
    SelectElement,
    DateRangePickerElement,
    BookingAccountSelectInput,
    NumberInputElement,
    DepartmentSelectInput,
    MemberSelectInput,
    ProductSelectInput,
    ProductTagSelectionInput,
    CheckboxElement,
    SupplierSelectInput,
    LanguageSelectInput,
    CategorySelectionInput,
    ListTableTagTypeSelection,
    DatePickerElement,
    EmailInputElement,
    CurrencyInputElement,
    TextInputElement
  },
  props: {
    tableIdentifier: {
      type: String,
      required: true
    },
    caption: {
      type: String,
      required: true
    },
    filterKey: {
      type: String,
      required: true
    },
    inputKey: {
      type: String,
      default: null
    },
    type: {
      type: String,
      required: true
    },
    defaultValue: {
      type: [String, Number, Object]
    },
    filterOptions: {
      type: Array,
      required: false
    },
    showResetButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    availableFilterOptions: [],
  }),
  methods: {
    setDefaultValue() {
      // Top Level filter is set on Query Params
      if (this.$route.query['filter_' + this.filterKey] !== undefined) {
        if (this.type === FILTER_DATETIME_RANGE) {
          let queryValue = this.$route.query['filter_' + this.filterKey].split(',');
          this.value = {
            startDate: queryValue[0],
            endDate: queryValue[1],
          }
          return;
        } else {
          if (this.type === FILTER_PRODUCT_TAG_MULTI || this.type === FILTER_PRODUCT_CATEGORY) {
              this.value =  this.$route.query['filter_' + this.filterKey].split(',').map(item => Number(item));
              return;
          }
          if (this.type === FILTER_BOOLEAN) {
            this.value = Number(this.$route.query['filter_' + this.filterKey]);
            return;
          }

          this.value = this.$route.query['filter_' + this.filterKey];
          return;
        }
      }

      // Filter is filter with sub Inputs
      if (this.inputKey !== null && this.inputKey !== undefined) {
        // Filter for sub-input set on query params
        if (this.$route.query['filter_' + this.inputKey] !== undefined) {

          this.value = this.$route.query['filter_' + this.inputKey];
          return;
        }
      }

      // Set defaultValue if existing and value not already set from query
      if (this.value === null) {
        // if(this.defaultValue !== undefined) {
        //     this.value = this.defaultValue;
        // }
        this.setDefaultValueForDateTimeRange();
      }
    },
    setDefaultValueForDateTimeRange() {
      if (this.type !== FILTER_DATETIME_RANGE) {
        return false;
      }

      if (this.defaultValue !== undefined) {
        this.value = this.defaultValue;
      } else {
        this.value = {
          startDate: null,
          endDate: null
        };
      }
    },
    resetValue() {
      this.value = null;
      this.setDefaultValueForDateTimeRange();

      // If its not a filter that is forced to be visible, it will be hidden when resetting the Value.
      this.hideFilterIfInitiallyHidden();
    },
    hideFilterIfInitiallyHidden() {
      let storeFilter = {...this.getFilterFromStore};
      if (!storeFilter.forceIsVisible) {
        storeFilter.isVisibleCurrently = false;

        this.$store.commit('listTable/filters/updateFilter', storeFilter);
      }
    },

    /**
     * Called when user selects a filter currently not displayed from the list that should be displayed.
     * @param filter
     */
    makeFilterVisible(filter) {
      let storeFilter = this.getFiltersFromStore.find(f => f.filterKey === filter.filterKey);
      storeFilter.isVisibleCurrently = true;
      this.updateStoreFilter(storeFilter);
    }
  },
  mounted() {
    this.setDefaultValue();

    if (!this.filterOptions) {
      switch (this.type) {
        case this.FILTER_LOGGABLE_TYPE:
          LogsRepository.getLoggableTypes()
            .then((res) => {
              this.availableFilterOptions = res.data.data.map(t => {
                return {
                  label: t,
                  value: t,
                }
              })
            });
          break;
        case this.FILTER_LOG_ACTIONS:
          LogsRepository.getLogActions()
            .then((res) => {
              this.availableFilterOptions = res.data.data.map(t => {
                return {
                  label: t,
                  value: t,
                }
              })
            });
      }
    }
  },
  computed: {
    FILTER_TEXT: () => FILTER_TEXT,
    FILTER_NUMERIC: () => FILTER_NUMERIC,
    FILTER_CURRENCY: () => FILTER_CURRENCY,
    FILTER_EMAIL: () => FILTER_EMAIL,
    FILTER_DATETIME: () => FILTER_DATETIME,
    FILTER_SELECT_SINGLE: () => FILTER_SELECT_SINGLE,
    FILTER_SELECT_MULTI: () => FILTER_SELECT_MULTI,
    FILTER_TAG_TYPE: () => FILTER_TAG_TYPE,
    FILTER_PRODUCT_CATEGORY: () => FILTER_PRODUCT_CATEGORY,
    FILTER_LANGUAGE_SELECT: () => FILTER_LANGUAGE_SELECT,
    FILTER_COUNTRY_SELECT: () => FILTER_COUNTRY_SELECT,
    FILTER_PRODUCT_SUPPLIER: () => FILTER_PRODUCT_SUPPLIER,
    FILTER_SUPPLIER: () => FILTER_SUPPLIER,
    FILTER_BOOLEAN: () => FILTER_BOOLEAN,
    FILTER_SINGLE_BOOLEAN: () => FILTER_SINGLE_BOOLEAN,
    FILTER_PRODUCT_TAG_SINGLE: () => FILTER_PRODUCT_TAG_SINGLE,
    FILTER_PRODUCT_TAG_MULTI: () => FILTER_PRODUCT_TAG_MULTI,
    FILTER_PRODUCT_SINGLE: () => FILTER_PRODUCT_SINGLE,
    FILTER_PRODUCT_MULTI: () => FILTER_PRODUCT_MULTI,
    FILTER_MEMBER: () => FILTER_MEMBER,
    FILTER_MEMBER_EMPLOYEES: () => FILTER_MEMBER_EMPLOYEES,
    FILTER_MEMBER_EMPLOYEES_MULTI: () => FILTER_MEMBER_EMPLOYEES_MULTI,
    FILTER_MEMBER_CUSTOMERS: () => FILTER_MEMBER_CUSTOMERS,
    FILTER_LOGGABLE_TYPE: () => FILTER_LOGGABLE_TYPE,
    FILTER_LOG_ACTIONS: () => FILTER_LOG_ACTIONS,
    FILTER_DEPARTMENT_SINGLE: () => FILTER_DEPARTMENT_SINGLE,
    FILTER_DEPARTMENT_MULTI: () => FILTER_DEPARTMENT_MULTI,
    FILTER_ORDER_ITEM_TYPE: () => FILTER_ORDER_ITEM_TYPE,
    FILTER_PRODUCT_SUPPLIER_MULTI: () => FILTER_PRODUCT_SUPPLIER_MULTI,
    FILTER_BOOKING_ACCOUNT: () => FILTER_BOOKING_ACCOUNT,
    FILTER_DATETIME_RANGE: () => FILTER_DATETIME_RANGE,
    FILTER_DEVICE: () => FILTER_DEVICE,
    FILTER_ROLES: () => FILTER_ROLES,
    FILTER_PERMISSIONS: () => FILTER_PERMISSIONS,

    getFilterOptions() {
      if (this.filterOptions && Array.isArray(this.filterOptions)) {
        if (this.filterOptions.length) {
          return this.filterOptions;
        }
      }

      return this.availableFilterOptions ?? [];
    },

    getOrderItemTypes() {
      return this.$luxcaddyConfig('order.itemTypes')
    },

    getFilterFromStore() {
      return this.$store.getters['listTable/filters/getFilterByIdentifierAndKey'](this.tableIdentifier, this.filterKey);
    },
    value: {
      get() {
        let filter = this.getFilterFromStore;

        if (this.originatesFromInput) {
          return filter.filterValue ? filter.filterValue[this.inputKey] : null;
        }

        return filter.filterValue;
      },
      set(value) {
        let filter = this.getFilterFromStore;

        if (this.originatesFromInput) {
          filter.filterValue[this.inputKey] = value;
        } else {
          filter.filterValue = value;
        }

        this.$store.commit('listTable/filters/updateFilter', {
          tableIdentifier: this.tableIdentifier,
          filterKey: this.filterKey,
          filterValue: filter.filterValue,
          ...filter
        });

      }
    },

    isFilterSet() {
      return isFilterSet(this.value, this.type);
    },
    /**
     * Determines if this component is rendered as a filter subInput.
     * @return {boolean}
     */
    originatesFromInput() {
      return this.inputKey !== null;
    },

    getOnlyMemberTypesValueWhenMemberFilter() {
      switch (this.type) {
        case FILTER_MEMBER:
        default:
          return [];
        case FILTER_MEMBER_EMPLOYEES:
          return ['employee'];
        case FILTER_MEMBER_CUSTOMERS:
          return ['customer'];
      }
    }
  }
}
</script>

